import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from '../../components/common/Loader';
// eslint-disable-next-line import/no-cycle
import { Editor } from '../../components/CV/Editor/Editor.component';
import { raiseToast } from '../../utils/raiseToasts';
import { SECTION_TYPE } from './CV.config';
import { actions, actions as cvActions } from './CV.slice';
import { cvThunks } from './CV.thunk';
import { getPages } from './CV.utils';

export const findParent = (element) => {
  if (element.sectionId) {
    return element;
  }

  return findParent(element.parentNode);
};

const CV = ({ editable = true, injectData, cvId, onPagesFinished }) => {
  const routeParams = useParams();
  const targetCvId = cvId || routeParams.id;
  const dispatch = useDispatch();
  const sectionConfig = useSelector(
    (state) => state.cv.present.config.sectionConfig,
  );
  if (
    !sectionConfig?.entries
      ?.map((section) => section.type)
      .includes('certificatesHeader')
  ) {
    dispatch(
      cvActions.addSection({
        sectionType: SECTION_TYPE.CERTIFICATES_HEADER,
        entries: [
          {
            sectionType: SECTION_TYPE.CERTIFICATES_ENTRY,
          },
        ],
      }),
    );
  }
  const employeeContext = useSelector(
    (state) => state.cv.present.config.employeeContext,
  );

  const dataLoaded = useSelector((state) => state.cv.present.config.dataLoaded);
  const [loading, setLoading] = useState(!!targetCvId);
  const [pagesFailed, setPagesFailed] = useState(false);
  const [pagesFinished, setPagesFinished] = useState(false);
  const navigate = useNavigate();
  const cvRef = useRef();
  useEffect(() => {
    if (!employeeContext && editable && !targetCvId) {
      navigate(-1);
    }
  }, [employeeContext]);

  useEffect(() => {
    if (pagesFinished) {
      dispatch(actions.clearHistory());
      onPagesFinished && onPagesFinished(cvRef.current);
    }
  }, [pagesFinished]);

  useEffect(() => {
    if (dataLoaded || !targetCvId) {
      setLoading(false);
      return;
    }

    setLoading(true);
    if (targetCvId && !injectData) {
      dispatch(
        cvThunks.loadCvThunk({
          cvId: targetCvId,
        }),
      ).then((result) => {
        setLoading(false);
        if (result.type === cvThunks.loadCvThunk.rejected.type) {
          navigate(-1);
        }
      });
    }

    if (injectData) {
      dispatch(cvThunks.injectCvDataThunk(injectData)).then((result) => {
        setLoading(false);
        if (result.type === cvThunks.loadCvThunk.rejected.type) {
          navigate(-1);
        }
      });
    }

    return () => {
      dispatch(actions.reset());
    };
  }, [cvId, injectData]);

  useEffect(() => {
    if (pagesFailed) {
      raiseToast.error(
        'Content takes too much space to fit into one page, and cannot be split up',
      );
      dispatch(actions.undo());
      setPagesFailed(false);
    }
  }, [pagesFailed]);

  const updateSectionHeight = (sectionId) => (element) => {
    dispatch(
      actions.updateSectionHeight({
        sectionId,
        height: element.offsetHeight,
      }),
    );
  };

  const handleDrag = (event) => {
    event.stopPropagation();
    event.dataTransfer.setData('draggedSection', event.target.sectionId);
  };

  const handleDrop = (event) => {
    event.stopPropagation();
    const targetElement = findParent(event.target);
    const targetSectionId = targetElement.sectionId;
    const draggedSectionId = event.dataTransfer.getData('draggedSection');
    dispatch(
      actions.reorder({
        draggedSectionId,
        targetSectionId,
      }),
    );
  };

  const setTextFormattingPanelVisibility = (value) => {
    dispatch(actions.setTextFormattingPanelVisibility(value));
  };

  return loading ? (
    <Loader />
  ) : (
    <Editor
      ref={cvRef}
      sectionConfig={sectionConfig}
      updateSectionHeight={updateSectionHeight}
      handleDrop={handleDrop}
      handleDrag={handleDrag}
      editable={editable}
      pages={getPages(
        sectionConfig,
        pagesFailed,
        setPagesFailed,
        pagesFinished,
        setPagesFinished,
      )}
      setTextFormattingPanelVisibility={setTextFormattingPanelVisibility}
      employeeContext={employeeContext}
      cvId={targetCvId}
    />
  );
};

export default CV;
