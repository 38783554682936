/* eslint-disable complexity */
/* eslint-disable radar/no-duplicate-string */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_REGISTER_COMPANY_URL,
  API_COMPANY_SIZES_LIST,
  API_COUNTRIES_LIST,
  API_COMPANY_URL,
} from '../../config/urls';
import { getAuthHeader, handleResponse } from '../../utils/api';
import { raiseToast } from '../../utils/raiseToasts';

const currentToken = localStorage.getItem('token');

const authHeaders = {
  'Authorization-Token': `Token ${currentToken}`,
};

export interface registerCompanyThunkArgs {
  company_name: string;
  country: string;
  company_size: number | null;
  full_name: string;
  password: string;
  password2: string;
  vat_number: string;
  user_invitation?: string;
}

const registerCompanyThunk = createAsyncThunk(
  'company/registerCompany',
  async (data: registerCompanyThunkArgs, { dispatch }) => {
    const request = fetch(API_REGISTER_COMPANY_URL, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      raiseToast.success('Successfully registered.');
    }

    return response;
  },
);

export interface updateCompanyInfoThunkArgs {
  company_name: string;
  country: string;
  company_size: number | null;
  vat_number: string;
  email: string;
  additional_email: string;
  address: string;
  website: string;
  link_to_clutch: string;
  phone_number: string;
  logo: File | string;
  id: number;
}

const updateCompanyInfoThunk = createAsyncThunk(
  'company/updateCompanyInfo',
  async (data: updateCompanyInfoThunkArgs, { dispatch }) => {
    const { id, ...companyData } = data;
    const formData = new FormData();
    companyData.company_name &&
      formData.append('name', companyData.company_name || '');
    companyData.country &&
      formData.append('country', companyData.country || '');
    companyData.company_size &&
      formData.append(
        'company_size',
        companyData.company_size.toString() || '',
      );
    companyData.vat_number &&
      formData.append('vat_number', companyData.vat_number || '');
    companyData.email && formData.append('email', companyData.email || '');
    companyData.additional_email &&
      formData.append('additional_email', companyData.additional_email || '');
    companyData.address &&
      formData.append('address', companyData.address || '');
    companyData.website &&
      formData.append('website', companyData.website || '');
    companyData.link_to_clutch &&
      formData.append('link_to_clutch', companyData.link_to_clutch || '');
    companyData.phone_number &&
      formData.append('phone_number', companyData.phone_number || '');
    typeof companyData.logo !== 'string' &&
      formData.append('logo', companyData.logo || '');

    const request = fetch(`${API_COMPANY_URL}${id}/`, {
      method: 'PATCH',
      body: formData,
      headers: authHeaders,
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      raiseToast.success('Successfully updated company info.');
    }

    return response;
  },
);

export interface resendVerificationLinkThunkArgs {
  email: string;
  id: string;
}

const resendVerificationLinkThunk = createAsyncThunk(
  'company/resendVerificationLink',
  async ({ email, id }: resendVerificationLinkThunkArgs, { dispatch }) => {
    const request = fetch(`${API_REGISTER_COMPANY_URL}${id}/resend/`, {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: { 'Content-Type': 'application/json' },
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      raiseToast.success('Successfully send the link.');
    }

    return response;
  },
);

const confirmEmailThunk = createAsyncThunk(
  'company/confirmEmail',
  async (token: string, { dispatch }) => {
    const request = fetch(`${API_REGISTER_COMPANY_URL}${token}/confirm/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      raiseToast.success('Successfully confirmed the email.');
    }

    return response;
  },
);

const getCompanySizesThunk = createAsyncThunk(
  'company/getCompanySizes',
  async (_data, { dispatch }) => {
    const request = fetch(`${API_COMPANY_SIZES_LIST}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });

    return await handleResponse(request, dispatch);
  },
);

const getCountriesThunk = createAsyncThunk(
  'company/getCountries',
  async (_data, { dispatch }) => {
    const request = fetch(API_COUNTRIES_LIST, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return await handleResponse(request, dispatch);
  },
);

const getCompanyInfoThunk = createAsyncThunk(
  'company/getCompanyInfo',
  async (companyId: number, { getState, dispatch }) => {
    const request = fetch(`${API_COMPANY_URL}${companyId}`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });

    return await handleResponse(request, dispatch);
  },
);

const getPartnerCompaniesThunk = createAsyncThunk(
  'company/getPartnerCompanies',
  async (_data, { getState, dispatch }) => {
    const request = fetch(`${API_COMPANY_URL}partner`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });

    return await handleResponse(request, dispatch);
  },
);

const stopPartnerCooperationThunk = createAsyncThunk(
  'company/stopPartnerCooperation',
  async (data: { id: number }, { getState, dispatch }) => {
    const request = fetch(`${API_COMPANY_URL}partner/${data.id}/`, {
      method: 'DELETE',
      headers: getAuthHeader(getState()),
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      raiseToast.success('Successfully updated cooperation status.');
    }

    return response;
  },
);

const sendInvitationToExistCompanyThunk = createAsyncThunk(
  'company/sendInvitationToExistCompany',
  async (
    data: { vat_number: string; country: string },
    { getState, dispatch },
  ) => {
    const request = fetch(`${API_COMPANY_URL}invitation/`, {
      method: 'POST',
      headers: getAuthHeader(getState()),
      body: JSON.stringify(data),
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      raiseToast.success('Successfully sent the invitation.');
    }

    return response;
  },
);

const acceptCompanyInvitationThunk = createAsyncThunk(
  'company/acceptCompanyInvitation',
  async (uuid: string, { getState, dispatch }) => {
    const request = fetch(`${API_COMPANY_URL}invitation/${uuid}/accept/`, {
      method: 'POST',
      headers: getAuthHeader(getState()),
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      raiseToast.success('Successfully accepted the invitation.');
    }

    return response;
  },
);

export const companyThunks = {
  registerCompanyThunk,
  updateCompanyInfoThunk,
  resendVerificationLinkThunk,
  confirmEmailThunk,
  getCompanySizesThunk,
  getCountriesThunk,
  getCompanyInfoThunk,
  getPartnerCompaniesThunk,
  sendInvitationToExistCompanyThunk,
  acceptCompanyInvitationThunk,
  stopPartnerCooperationThunk,
};
