import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_LEADS } from '../../../config/urls';
import { getAuthHeader, handleResponse } from '../../../utils/api';
import { raiseToast } from '../../../utils/raiseToasts';

const getLeadsThunk = createAsyncThunk(
  'leadsList/getLeads',
  async (_, { getState, dispatch }) => {
    const request = fetch(`${API_LEADS}`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });

    return await handleResponse(request, dispatch);
  },
);

const createLeadThunk = createAsyncThunk(
  'leadsList/createLead',
  async (data: { lead: BodyInit }, { getState, dispatch }) => {
    const request = fetch(`${API_LEADS}`, {
      method: 'POST',
      body: JSON.stringify(data.lead),
      headers: {
        ...getAuthHeader(getState()),
      },
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      raiseToast.success('Successfully added the lead.');
    }

    return response;
  },
);

const updateLeadThunk = createAsyncThunk(
  'leadsList/updateLead',
  async (data: any, { getState, dispatch }) => {
    const request = fetch(`${API_LEADS}${data.id}/`, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        ...getAuthHeader(getState()),
      },
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      raiseToast.success('Successfully updated the lead.');
    }
  },
);

const deleteLeadThunk = createAsyncThunk(
  'leadsList/deleteLead',
  async (data: { leadId: string }, { getState, dispatch }) => {
    const request = fetch(`${API_LEADS}${data.leadId}/`, {
      method: 'DELETE',
      headers: {
        ...getAuthHeader(getState()),
      },
    });

    const response = await handleResponse(request, dispatch);

    if (response) {
      raiseToast.success(`Successfully deleted lead.`);
    }
  },
);

export const leadsListThunks = {
  getLeadsThunk,
  createLeadThunk,
  updateLeadThunk,
  deleteLeadThunk,
};
