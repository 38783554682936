import { createSearchParams, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppPaths } from '../../../config/AppPaths';
import {
  priorities,
  profileProps,
  statuses,
} from '../../../containers/Leads/Leads.config';
import { addDotsToString, sortProfilesByStatus } from '../../../Helpers';
import { raiseToast } from '../../../utils/raiseToasts';
import { AddNewItemButton } from '../../common/Buttons/AddNewItemButton/AddNewItemButton';
import { NoResult } from '../../common/NoResult';
import { Tooltip } from '../../common/Tooltip/Tooltip.component';
import {
  HeadContainer,
  Line,
  StyledBox,
  StyledHeadline,
  StyledLink,
  StyledList,
  StyledListItem,
  StyledListItemButton,
  StyledListItemText,
} from './RequestDev.styles';

const stringLimit = 56;

export const RequestDev = ({
  leadStatus,
  leadId,
  leadProfiles,
  addProfile,
}) => {
  const { t } = useTranslation(['company'], {
    keyPrefix: 'company_details.projects',
  });

  const handleAddingButton = () => {
    if (leadStatus === 'ARCHIVED') {
      raiseToast.error(
        'You are trying to add lead profile to archived lead. Please make it active.',
      );
    } else {
      addProfile(leadId);
    }
  };

  return (
    <StyledBox>
      <HeadContainer>
        <StyledHeadline>{t('request_dev')}</StyledHeadline>
        <AddNewItemButton action={handleAddingButton} />
      </HeadContainer>
      <Line />
      <StyledList>
        {leadProfiles.length ? (
          sortProfilesByStatus(
            leadProfiles,
            profileProps,
            statuses,
            priorities,
          ).map((profile) => (
            <StyledListItem key={`profile-${profile.id}`}>
              <StyledLink
                className="link"
                to={{
                  pathname: generatePath(AppPaths.leadDetail, {
                    id: leadId,
                  }),
                  search: `?${createSearchParams({
                    leadProfile: profile.id.toString(),
                  })}`,
                  state: { prevPath: AppPaths.leads },
                }}
              >
                <StyledListItemButton>
                  <StyledListItemText
                    primary={
                      profile.name.length > stringLimit ? (
                        <Tooltip title={profile.name} position="regular">
                          <span>
                            {addDotsToString(profile.name, stringLimit)}
                          </span>
                        </Tooltip>
                      ) : (
                        profile.name
                      )
                    }
                  />
                </StyledListItemButton>
              </StyledLink>
            </StyledListItem>
          ))
        ) : (
          <NoResult message="No profiles" />
        )}
      </StyledList>
    </StyledBox>
  );
};
