/* eslint-disable radar/no-duplicate-string */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { EmployeeWritableDTO } from '../../store/types/Employees.types';
import { PersistedStoreKeys } from '../../config/PersistedStoreKeys';
import {
  API_EMPLOYEES_URL,
  API_LEADS,
  API_SUGGESTED_SOURCES_URL,
  API_SEARCH_URL,
} from '../../config/urls';
import {
  EmployeeIdType,
  EmployeeTableDataInterface,
} from '../../store/types/Store.candidates.types';
import {
  getAuthHeader,
  getAuthHeaderMultipart,
  getExternalClientAuthHeader,
  handleResponse,
} from '../../utils/api';
import { raiseToast } from '../../utils/raiseToasts';
import {
  generateClientListCandidatesUrl,
  generateClientListEmployeesUrl,
  generateExternalClientListAllEmployeesUrl,
  generatePartnerListAllEmployeesUrl,
} from '../../utils/urlGenerators';

interface GetEmployeeThunkArgs {
  employeeId: string;
}

const getEmployeeThunk = createAsyncThunk<
  EmployeeTableDataInterface,
  GetEmployeeThunkArgs
>('employees/getEmployee', async (data, { getState, dispatch }) => {
  const request = fetch(`${API_EMPLOYEES_URL}${data.employeeId}`, {
    method: 'GET',
    headers: getAuthHeader(getState()),
  });

  return await handleResponse(request, dispatch);
});

interface SaveStateEmployeeThunk {
  search: string | null;
  owner: string | null;
  technologies: string[];
  list?: string | null;
  employeeType?: 'employee';
}

interface SaveStateSalesThunk {
  search: string;
  owner: string;
  technologies: string[];
  list: string;
  employeeType?: 'sales';
}

interface SaveStateClientThunk {
  search: string;
  employeeType?: 'client';
}

type SaveStateThunk =
  | SaveStateEmployeeThunk
  | SaveStateSalesThunk
  | SaveStateClientThunk;

const saveStateThunk = createAsyncThunk<SaveStateThunk, SaveStateThunk>(
  'employees/saveState',
  async (data) => {
    switch (data.employeeType) {
      case 'employee':
        localStorage.setItem(
          PersistedStoreKeys.employeeFilters,
          JSON.stringify(data),
        );
        break;
      case 'sales':
        localStorage.setItem(
          PersistedStoreKeys.salesFilters,
          JSON.stringify(data),
        );
        break;
      case 'client':
        localStorage.setItem(
          PersistedStoreKeys.clientFilters,
          JSON.stringify(data),
        );
        break;
      default:
        throw Error(`Cannot find this type of employee: ${data.employeeType}`);
    }
    return data;
  },
);

export interface GetEmployeesThunkArgs {
  items_per_page: number;
  count: number;
  results?: any;
  sortOrder?: { sortBy: string; order: '' | '-' };
  page?: number | '';
  itemsPerPage?: number | '';
  technologies?: string | string[];
  employmentType?: string;
  seniority?: string;
  rateOfPayPln?: string;
}

const getEmployeesThunk = createAsyncThunk<
  GetEmployeesThunkArgs,
  GetEmployeesThunkArgs
>(
  'employees/getEmployees',
  async (
    {
      sortOrder = { order: '', sortBy: 'updated_at' },
      page = '',
      itemsPerPage = '',
    },
    { getState, dispatch },
  ) => {
    const request = fetch(
      `${API_EMPLOYEES_URL}?ordering=${sortOrder.order}${sortOrder.sortBy}${
        +page > 1 ? `&page=${page}` : ''
      }&page_size=${itemsPerPage}`,
      {
        method: 'GET',
        headers: getAuthHeader(getState()),
      },
    );

    const response = await handleResponse(request, dispatch);

    localStorage.removeItem('employees/getEmployees');
    localStorage.setItem('employees/getEmployees', JSON.stringify(response));

    return response;
  },
);

export interface GetSmartSearchEmployeesThunkArgs {
  skills?: string[];
  rate_of_pay?: number;
  seniority?: string;
  english_level?: string;
  availability?: string;
  availability_to?: string;
  employment_type?: string;
  additional_language?: string;
  additional_language_level?: string;
}

const getSmartSearchEmployeesThunk = createAsyncThunk<
  GetSmartSearchEmployeesThunkArgs,
  any
>(
  'employees/getSmartSearchEmployeesThunk',
  async (data, { getState, dispatch }) => {
    const request = fetch(API_SEARCH_URL, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: getAuthHeader(getState()),
    });

    return await handleResponse(request, dispatch);
  },
);

const getWatchedEmployeesThunk = createAsyncThunk<any>(
  'employees/getWatchedEmployees',
  async (_data, { getState, dispatch }) => {
    const request = fetch(
      `${API_EMPLOYEES_URL}?observed=true&disable_pagination=true`,
      {
        method: 'GET',
        headers: getAuthHeader(getState()),
      },
    );

    return await handleResponse(request, dispatch);
  },
);

const getEmployeesWithValidExpThunk = createAsyncThunk(
  'employees/getEmployeesWithValidExpThunk',
  async (_data, { getState, dispatch }) => {
    const request = fetch(
      `${API_EMPLOYEES_URL}?show_valid_exp=true&page_size=999&only_own_company=1`,
      {
        method: 'GET',
        headers: getAuthHeader(getState()),
      },
    );

    const response = await handleResponse(request, dispatch);

    localStorage.removeItem('employees/getEmployees');
    localStorage.setItem('employees/getEmployees', JSON.stringify(response));

    return response;
  },
);

const getPossibleCandidatesThunk = createAsyncThunk(
  'employees/getPossibleCandidates',
  async (
    data: { leadId: number; leadProfileId: number },
    { getState, dispatch },
  ) => {
    const request = fetch(
      `${API_LEADS}${data.leadId}/profiles/${data.leadProfileId}/candidates/possible/`,
      {
        method: 'GET',
        headers: getAuthHeader(getState()),
      },
    );

    return await handleResponse(request, dispatch);
  },
);

const getPublicEmployeesThunk = createAsyncThunk(
  'employees/getPublicEmployees',
  async (_data, { getState, dispatch }) => {
    const request = fetch(`${API_EMPLOYEES_URL}public`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });

    return await handleResponse(request, dispatch);
  },
);
const getMyEmployeesThunk = createAsyncThunk(
  'employees/getMyEmployees',
  async (_data, { getState, dispatch }) => {
    const request = fetch(
      `${API_EMPLOYEES_URL}?page_size=999&only_own_company=1`,
      {
        method: 'GET',
        headers: getAuthHeader(getState()),
      },
    );

    return await handleResponse(request, dispatch);
  },
);

export interface UpdateEmployeeActivityThunkArgs {
  employeeId: string;
  payload: {
    is_active: boolean;
  };
}

const updateEmployeeActivityThunk = createAsyncThunk<
  EmployeeTableDataInterface,
  UpdateEmployeeActivityThunkArgs
>('employees/updateEmployeeActivity', async (data, { getState, dispatch }) => {
  const request = fetch(`${API_EMPLOYEES_URL}${data.employeeId}/`, {
    method: 'PATCH',
    body: JSON.stringify(data.payload),
    headers: {
      ...getAuthHeader(getState()),
    },
  });

  const response = await handleResponse(request, dispatch);
  if (response) {
    raiseToast.success('Successfully updated candidate.');
  }

  return await dispatch(
    getEmployeeThunk({ employeeId: data.employeeId }),
  ).unwrap();
});

export interface UpdateEmployeeObservedStatusThunkArgs {
  employeeId: string;
  isMyProfile?: boolean;
  payload: {
    observed: boolean;
  };
}

const updateEmployeeObservedStatusThunk = createAsyncThunk<
  EmployeeTableDataInterface,
  UpdateEmployeeObservedStatusThunkArgs
>(
  'employees/updateEmployeeObservedStatus',
  async (data, { getState, dispatch }) => {
    const request = fetch(`${API_EMPLOYEES_URL}${data.employeeId}/observe/`, {
      method: 'PATCH',
      body: JSON.stringify(data.payload),
      headers: {
        ...getAuthHeader(getState()),
      },
    });

    return data.isMyProfile
      ? await handleResponse(request, dispatch)
      : await dispatch(
          getEmployeeThunk({ employeeId: data.employeeId }),
        ).unwrap();
  },
);

interface CommonEmployeeThunkArgs {
  employeeData: FormData;
  id?: string;
}

const createEmployeeThunk = createAsyncThunk<unknown, CommonEmployeeThunkArgs>(
  'employees/createEmployee',
  async (data, { getState, dispatch }) => {
    const request = fetch(`${API_EMPLOYEES_URL}`, {
      method: 'POST',
      body: data.employeeData,
      headers: {
        ...getAuthHeaderMultipart(getState()),
      },
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      raiseToast.success('Successfully added the candidate.');
    }

    return response;
  },
);

const updateEmployeeThunk = createAsyncThunk<unknown, CommonEmployeeThunkArgs>(
  'employees/updateEmployee',
  async (data, { getState, dispatch }) => {
    const request = fetch(`${API_EMPLOYEES_URL}${data.id}/`, {
      method: 'PUT',
      body: data.employeeData,
      headers: {
        ...getAuthHeaderMultipart(getState()),
      },
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      raiseToast.success('Successfully update the candidate info.');
    }

    return response;
  },
);

const partialUpdateEmployeeThunk = createAsyncThunk(
  'employees/partialUpdate',
  async (
    data: { employeeId: EmployeeIdType; employeeData: EmployeeWritableDTO },
    { getState, dispatch },
  ) => {
    const request = fetch(`${API_EMPLOYEES_URL}${data.employeeId}/`, {
      method: 'PATCH',
      body: JSON.stringify(data.employeeData),
      headers: { ...getAuthHeader(getState()) },
    });

    const response = await handleResponse(request, dispatch);

    if (response) {
      raiseToast.success('Successfully updated the candidate.');
    }

    return response;
  },
);

export interface DeleteEmployeeThunkArgs {
  employeeId: string;
  fullName: string;
}

const deleteEmployeeThunk = createAsyncThunk<void, DeleteEmployeeThunkArgs>(
  'employees/deleteEmployee',
  async (data, { getState, dispatch }) => {
    const request = fetch(`${API_EMPLOYEES_URL}${data.employeeId}/`, {
      method: 'DELETE',
      headers: {
        ...getAuthHeader(getState()),
      },
    });

    const response = await handleResponse(request, dispatch);

    if (response) {
      raiseToast.success(`Successfully deleted ${data.fullName}`);
    }
  },
);

const exportEmployeesThunk = createAsyncThunk<unknown, unknown>(
  'employees/exportEmployees',
  async (data, { getState, dispatch }) => {
    const request = fetch(`${API_EMPLOYEES_URL}export/`, {
      method: 'POST',
      body: JSON.stringify({
        ids: Array.from(data as unknown[]),
      }),
      headers: {
        ...getAuthHeader(getState()),
      },
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      raiseToast.success('Candidates successfully exported.');
    }
    return response;
  },
);

const getSuggestedSourcesThunk = createAsyncThunk(
  'employees/getSuggestedSources',
  async (_data, { getState, dispatch }) => {
    const request = fetch(`${API_SUGGESTED_SOURCES_URL}`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });

    return await handleResponse(request, dispatch);
  },
);

const getClientListProfilesEmployeeThunk = createAsyncThunk(
  'emplyoyees/clientListProfiles',
  async (data: { clientListId: string }, thunkAPI) => {
    const request = fetch(generateClientListEmployeesUrl(data.clientListId), {
      method: 'GET',
      headers: getAuthHeader(thunkAPI.getState()),
    });

    return await handleResponse(request);
  },
);

const getPartnerListCandidatesThunk = createAsyncThunk(
  'clientListCandidate/getCandidatesPartner',
  async (
    data: { listId: string; partnerId: number },
    { getState, dispatch },
  ) => {
    const request = fetch(
      generatePartnerListAllEmployeesUrl(data.listId, data.partnerId),
      {
        method: 'GET',
        headers: getAuthHeader(getState()),
      },
    );
    return await handleResponse(request, dispatch);
  },
);

const getExternalClientListCandidatesThunk = createAsyncThunk(
  'clientListCandidate/getExternalCandidatesClientList',
  async (listId: string, { getState }) => {
    const request = fetch(generateExternalClientListAllEmployeesUrl(listId), {
      method: 'GET',
      headers: getExternalClientAuthHeader(getState()),
    });
    return await handleResponse(request);
  },
);

const getClientListCandidatesThunk = createAsyncThunk(
  'clientListCandidate/getCandidatesClient',
  async (data: { clientId: string }, { getState, dispatch }) => {
    const request = fetch(generateClientListCandidatesUrl(data.clientId), {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

export const employeeThunks = {
  getEmployeesThunk,
  getSmartSearchEmployeesThunk,
  getWatchedEmployeesThunk,
  getEmployeesWithValidExpThunk,
  getPossibleCandidatesThunk,
  updateEmployeeActivityThunk,
  updateEmployeeObservedStatusThunk,
  partialUpdateEmployeeThunk,
  getEmployeeThunk,
  updateEmployeeThunk,
  createEmployeeThunk,
  deleteEmployeeThunk,
  getPublicEmployeesThunk,
  getMyEmployeesThunk,
  saveStateThunk,
  exportEmployeesThunk,
  getSuggestedSourcesThunk,
  getClientListProfilesEmployeeThunk,
  getPartnerListCandidatesThunk,
  getClientListCandidatesThunk,
  getExternalClientListCandidatesThunk,
};
